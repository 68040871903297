<template>
    <transition name="modal-fade">
        <div class="modal-backdrop" v-if="showModal">
            <div class="modal" role="dialog" aria-labelledby="modalTitle" aria-describedby="modalDescription">
                <v-banner avatar="https://cdn.vuetifyjs.com/docs/images/logos/v.svg" stacked>
                    <template>
                        <div class="modal-body">
                            <v-dialog v-model="dialog" max-width="500" class="options-container">
                                <v-card title="Pagar Suscripción." class="mx-auto">
                                    <v-card-text class="text-center">
                                        <br>
                                        <v-img :src="require('../../assets/wallet_tron.png')" class="my-3" contain
                                            height="200" />
                                        <br>

                                        <!-- <p style="line-height: 115%;margin-bottom: 0.25cm;background: transparent;">
                                            <strong style="font-weight: bold;">WALLET: </strong>
                                            <strong v-on:focus="$event.target.select()" ref="myWallet"
                                                style="font-weight: bold;">{{ this.wallet }}</strong>
                                            <v-btn class="mx-2" fab dark small color="secondary" @click="copyClipboard">
                                                <v-icon dark>
                                                    mdi-clipboard-outline
                                                </v-icon>
                                            </v-btn>
                                        </p> -->
                                        <br>
                                        <p style="line-height: 115%;margin-bottom: 0.25cm;background: transparent;">
                                            <strong style="font-weight: bold; color: red;">Atención!!</strong>
                                        </p>
                                        <br>
                                        <p style="line-height: 115%;margin-bottom: 0.25cm;background: transparent;">
                                            <strong style="font-weight: bold; color: red">Revisar la red de la
                                                billetera.<br>
                                                Red de envío TRON. En observaciones / comentarios añadir el
                                                email del usuario registrado en nuestra plataforma.
                                            </strong>
                                        </p>
                                        <v-row justify="center">
                                            <v-col cols="5" xs="5" sm="5" md="5"
                                                class="flex-grow-0 flex-shrink-0"></v-col>
                                            <v-col cols="2" xs="2" sm="2" md="2" class="flex-grow-0 flex-shrink-0">
                                                <v-text-field class="text-center" @change="onChange($event)">{{
                                                    this.cantidad }}</v-text-field>
                                            </v-col>
                                            <v-col cols="5" xs="5" sm="5" md="5"
                                                class="flex-grow-0 flex-shrink-0"></v-col>
                                        </v-row>

                                        <br>
                                        <v-card class="pa-2">
                                            <v-data-table :headers="headers" :items="items" hide-default-footer>
                                                <template v-slot:[`item.actions`]="{ item }">
                                                    <v-icon small class="mr-2"
                                                        @click="handleClick(item, 'pay')">mdi-play</v-icon>
                                                </template>
                                            </v-data-table>
                                        </v-card>
                                        <br>
                                        <p style="line-height: 115%;margin-bottom: 0.25cm;background: transparent;">
                                            <strong style="font-weight: bold;">PRECIO: {{ this.total }} $</strong>
                                        </p>
                                        <br>
                                        <div id="AmazonPayButton"></div>
                                        <!-- <form ref="form" action="https://www.coinpayments.net/index.php" method="post"
                                            target="_top">
                                            <input type="hidden" name="cmd" value="_pay">
                                            <input type="hidden" name="reset" value="1">
                                            <input type="hidden" name="want_shipping" value="0">
                                            <input type="hidden" name="merchant"
                                                value="3bf9f9f8b9d9c74b387a3fa6339f5dd8">
                                            <input type="hidden" name="currency" value="LTC">
                                            <input type="hidden" name="amountf" value="10.00">
                                            <input type="hidden" name="item_name" value="Test Item">
                                            <input type="hidden" name="quantity" value="1">
                                            <input type="hidden" name="allow_quantity" value="1">
                                            <input type="hidden" name="success_url"
                                                value="">
                                            <input type="hidden" name="cancel_url"
                                                value="http://localhost:8082/result_pay?status=CANCEL">
                                            <input type="image"
                                                src="https://www.coinpayments.net/images/pub/buynow-ani-3.png"
                                                alt="Comprar ahora con CoinPayments.net" v-on:click="payWithCrypto">
                                            <v-btn class="mx-2" fab dark small color="secondary" @click="payCoinpayment">
                                                <v-icon dark>
                                                    mdi-clipboard-outline
                                                </v-icon>
                                            </v-btn>
                                        </form> -->
                                        <v-btn class="mx-2" fab dark small color="secondary" @click="payCoinpayment">
                                            <v-icon dark>
                                                mdi-clipboard-outline
                                            </v-icon>
                                        </v-btn>
                                    </v-card-text>
                                    <v-btn class="mx-2" fab dark small color="secondary" @click="createForm">
                                        <v-icon dark>
                                            mdi-clipboard-outline
                                        </v-icon>
                                    </v-btn>
                                    <v-divider></v-divider>
                                </v-card>
                            </v-dialog>
                        </div>
                    </template>
                </v-banner>
            </div>
            <div class="w-auto">
                <DialogModal ref="modalDialog" />
            </div>
        </div>

    </transition>

</template>

<script>
import * as Address from '@/services/direction.js'
import DialogModal from '@/components/utils/DialogClipboardComponent.vue'
// import Sumatorio from '@/components/utils/SumatorioComponent.vue'
import UserService from '../../services/user.service'
import EventBus from "../../common/EventBus"


// const UniPaymentClient = UniPayment.uni_payment_client;
// const configuration = require('./configuration.json');
// // const client = new UniPaymentClient(configuration);



export default {
    name: 'CriptoModalComponent',
    components: {
        DialogModal,
        // Sumatorio
    },
    data: function () {
        return {
            showModal: true,
            idSuscripcion: '',
            tituloSuscripcion: '',
            tipo: '',
            bots: '',
            meses: '',
            precio: '',
            cantidad: 1,
            total: '',
            wallet: '',
            dialog: false,
            headers: [
                { text: "Nombre", value: "nombre", align: "center" },
                { text: "Dias", value: "dias", align: "center" },
                { text: "Limite Bot", value: "limiteInversionPorBot", align: "center" },
                { text: "Precio / $", value: "precio", align: "center" },
                // { text: 'Actions', value: 'actions', sortable: false, align: "center" }
            ],
            items: [],
            item: '',
            formButton: ''
        }
    },
    computed: {

    },
    methods: {
        openModal(value) {
            this.item = value
            this.dialog = true
            this.total = this.precio
            this.items.push(value)
        },

        closeAlert() {
            this.isShow = false
        },
        onChange(value) {
            // console.log(this.item)
            // console.log(value)
            this.total = Math.round((this.precio * value) * 100) / 100
            this.cantidad = value
            // console.log(this.exchange.exchange)
        },
        async copyClipboard() {
            try {
                await navigator.clipboard.writeText(this.wallet);
                // this.isShow = true
                this.$refs.modalDialog.openModal()
                this.$refs.modalDialog.msg = 'Se ha copiado la wallet en su portapapeles.'
                this.$refs.modalDialog.type = 'success'
                // alert('Copied');
            } catch ($e) {
                this.$refs.modalDialog.openModal()
                this.$refs.modalDialog.msg = 'Ocurrió un error al copiar la wallet al portapapeles.'
                this.$refs.modalDialog.type = 'error'
            }
        },
        receiveEmit(value) {
            if (value === 'sumar') {
                this.sumarCantidad()
            } else {
                this.restarCantidad()
            }
        },
        sumarCantidad() {
            this.cantidad++
            this.total = this.precio * this.cantidad
        },
        restarCantidad() {
            if (this.cantidad > 1) {
                this.cantidad--
                this.total = this.precio * this.cantidad
            }

        },
        handleClick(value, action) {
            switch (action) {
                case 'pay':
                    console.log(value)
                    UserService.cryptoPay(value.id).then(
                        response => {
                            console.log(response)
                            // this.itemsTipos = response.data
                        },
                        error => {
                            this.content =
                                (error.response &&
                                    error.response.data &&
                                    error.response.data.message) ||
                                error.message ||
                                error.toString();
                            if (error.response && error.response.status === 403) {
                                EventBus.dispatch("logout");
                            }
                        }
                    );
                    break;
            }
        },
        createForm() {
            UserService.cryptoPay(this.item.id).then(
                response => {
                    console.log(response)
                    window.open(response, '_blank');
                    // this.formButton = response
                    // this.itemsTipos = response.data
                },
                error => {
                    this.content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    if (error.response && error.response.status === 403) {
                        EventBus.dispatch("logout");
                    }
                }
            );
        },
        payWithCrypto() {
            console.log(this.item)
            let url = 'http://localhost:8082/result_cripto?idSuscripcion=' + this.id
            // this.$refs.form[4].value = 'USDT'
            // this.$refs.form[5].value = this.precio
            this.$refs.form[5].value = 0.005
            this.$refs.form[6].value = this.item.nombre + ' ' + this.item.descripcion + ' para ' + this.cantidad + ' bots.'
            this.$refs.form[8].value = this.cantidad
            this.$refs.form[10].value = url
            console.log(this.$refs.form)
            // this.$refs.form.submit()
        },
        payCoinpayment() {
            UserService.getTransferCoinPayment(this.item.id).then(
                response => {
                    console.log(response)
                },
                error => {
                    this.content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    if (error.response && error.response.status === 403) {
                        // EventBus.dispatch("logout");
                    }
                }
            );
        },

    },
    mounted() {
        this.wallet = Address.WALLET

    }
}
</script>