<template>
  <v-container class="bg-surface-variant">
    <div class="d-flex flex-column mb-6 bg-surface-variant">
      <v-row no-gutters style="flex-wrap: nowrap;">
        <v-col>
          <v-card elevation="2" outlined shaped>
            <v-row justify="center">
              <v-card-title class="text-h5">{{ titleOrders }}</v-card-title>
            </v-row>

            <v-row no-gutters>
              <v-col cols="0" xs="0" sm="0" md="2" class="flex-grow-0 flex-shrink-0"></v-col>
              <v-col cols="12" xs="0" sm="0" md="8" class="flex-grow-0 flex-shrink-0">
                <v-card elevation="2" outlined shaped>
                  <br>
                  <v-row no-gutters>

                    <v-col cols="2" xs="0" sm="0" md="2" class="flex-grow-0 flex-shrink-0"></v-col>
                    <v-col cols="4" xs="0" sm="0" md="4" class="flex-grow-0 flex-shrink-0">
                      <v-row no-gutters justify="center">
                        <v-card>
                          <v-list-item-title class="text-center">Fecha Inicio:</v-list-item-title>
                          <v-list-item-title class="text-center">{{ initDate }}</v-list-item-title>
                        </v-card>
                      </v-row>
                    </v-col>
                    <v-col cols="4" xs="0" sm="0" md="4" class="flex-grow-0 flex-shrink-0">
                      <v-row no-gutters justify="center">
                        <v-card>
                          <v-btn :color="colors" @click="statusBot">{{ msgBtn }}</v-btn>
                        </v-card>
                      </v-row>
                    </v-col>
                  </v-row>
                  <br>
                  <vDivider></vDivider>
                  <br>
                  <v-row no-gutters>
                    <v-col cols="2" xs="0" sm="0" md="2" class="flex-grow-0 flex-shrink-0"></v-col>
                    <v-col cols="4" xs="0" sm="0" md="4" class="flex-grow-0 flex-shrink-0">
                      <v-row no-gutters justify="center">
                        <v-card>
                          <v-list-item-title class="text-center">Precio Actual:</v-list-item-title>
                          <v-list-item-title class="text-center">{{ currentPrice }}</v-list-item-title>
                        </v-card>
                      </v-row>
                    </v-col>
                    <v-col cols="4" xs="0" sm="0" md="4" class="flex-grow-0 flex-shrink-0">
                      <v-row no-gutters justify="center">
                        <v-card>
                          <v-list-item-title class="text-center">Saldo Bot:</v-list-item-title>
                          <v-list-item-title class="text-center">{{ available }}</v-list-item-title>
                        </v-card>
                      </v-row>
                    </v-col>
                  </v-row>
                  <br>
                  <vDivider></vDivider>
                  <br>
                  <v-row no-gutters>
                    <v-col cols="2" xs="0" sm="0" md="2" class="flex-grow-0 flex-shrink-0"></v-col>
                    <v-col cols="4" xs="0" sm="0" md="4" class="flex-grow-0 flex-shrink-0">
                      <v-row no-gutters justify="center">
                        <v-card>
                          <v-list-item-title class="text-center">Beneficios:</v-list-item-title>
                          <v-list-item-title class="text-center">{{ profits }}</v-list-item-title>
                        </v-card>
                      </v-row>
                    </v-col>
                    <v-col cols="4" xs="0" sm="0" md="4" class="flex-grow-0 flex-shrink-0">
                      <v-row no-gutters justify="center">
                        <v-card>
                          <v-list-item-title class="text-center">Comisiones:</v-list-item-title>
                          <v-list-item-title class="text-center">{{ fees }}</v-list-item-title>
                        </v-card>
                      </v-row>

                    </v-col>
                  </v-row>
                  <br>
                  <vDivider></vDivider>
                  <br>
                  <v-row no-gutters>
                    <v-col cols="1" xs="0" sm="0" md="1" class="flex-grow-0 flex-shrink-0"></v-col>
                    <v-col cols="10" xs="0" sm="0" md="10" class="flex-grow-0 flex-shrink-0">
                      <v-row no-gutters justify="center">
                        <v-card>
                          <ChartComponent></ChartComponent>
                        </v-card>
                      </v-row>
                    </v-col>
                  </v-row>
                  <br>
                </v-card>
              </v-col>
            </v-row>
            <vDivider></vDivider>
            <br>
            <v-row no-gutters>
              <v-col cols="2" xs="0" sm="0" md="2" class="flex-grow-0 flex-shrink-0"></v-col>
              <v-col cols="8" xs="12" sm="12" md="8" class="flex-grow-0 flex-shrink-0">
                <v-card class="pa-1">
                  <v-data-table :headers="headers" :items="itemsWithIndex" :search="search"
                    class="elevation-1"></v-data-table>
                  <!-- <v-data-table :headers="headers" :items="itemsWithIndex" item-key="type" :search="search" :item-class="getMyClass"
                    class="elevation-1">
                    <template v-slot:body="{ items }">
                      <tbody>
                        <tr :class="key === 'BID' ? 'compraClass' : 'ventaClass'" v-for="(item, key) in items"
                          :key="item.type">
                          <td>{{ item.originalAmount }}</td>
                          <td>{{ item.price }}</td>
                          <td>{{ item.feeAmount }}</td>
                          <td>{{ item.type }}</td>
                        </tr>
                      </tbody>
                    </template>
</v-data-table> -->
                </v-card>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import Bot from "../../models/bot";
import UserService from '../../services/user.service';
import EventBus from "../../common/EventBus";
import ChartComponent from '../../components/utils/ChartComponent.vue'

export default {
  name: 'OrdersComponent',
  components: {
    ChartComponent
  },
  data() {
    return {
      idBot: '',
      bot: new Bot(),
      titleOrders: "",
      pair1: "",
      pair2: "",
      initDate: "",
      available: '',
      startPrice: "",
      currentPrice: "",
      profits: "",
      fees: "",
      // Table
      headers: [
        { text: "Tipo", value: "typeCompra", align: "center" },
        { text: "Fecha Compra", value: "fechaCompra", align: "center" },
        // { text: "Cantidad Comprado", value: "amountCompra", align: "center" },
        { text: "Precio Compra", value: "precioCompra", align: "center" },
        { text: "Tipo", value: "typeVenta", align: "center" },
        { text: "Fecha Venta", value: "fechaVenta", align: "center" },
        // { text: "Cantidad Vendido", value: "amountVenta", align: "center" },
        { text: "Precio Venta", value: "precioVenta", align: "center" },
        { text: "Volumen", value: "amountCompra", align: "center" },
      ],
      items: [],
      right: null,
      search: '',
      start: false,
      colors: "success",
      msgBtn: ''
    };
  },
  computed: {
    itemsWithIndex() {
      return this.items.map((item, index) => ({ ...item, index: index }))
    }
  },
  created() {
  },
  mounted() {
    // this.loadChart()
    if (this.$route.params.idBot !== null) {
      this.idBot = this.$route.params.idBot
      UserService.getStatusBot(this.idBot).then(
        response => {
          // console.log(response.data)
          if (response.data === 'NOT INIT') {
            UserService.getBot(this.idBot).then(
              response => {
                this.bot = response.data
                this.start = false
                this.msgBtn = 'START'
                this.colors = 'success'
              }
            );

          } else {
            this.loadData(response.data);
          }

        },
        error => {
          this.content =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
          if (error.response && error.response.status === 403) {
            EventBus.dispatch("logout");
          }
        }
      );
    }

  },
  methods: {
    loadData(data) {
      if (data !== 'NOT INIT') {
        this.bot = data.bot;
        // console.log(data)
        this.titleOrders = this.bot.pair;
        let arrayPairs = this.bot.pair.split("-");
        // console.log(arrayPairs[0])
        this.pair1 = arrayPairs[0];
        this.pair2 = arrayPairs[1];
        this.initDate = data.start_time
        this.available = data.available
        this.startPrice = data.start_price
        this.currentPrice = data.currentPrice
        this.fees = data.fees
        this.profits = data.profits
        // console.log(this.bot.started)
        if (this.bot.started === false) {
          this.start = false
          this.msgBtn = 'START'
          this.colors = 'success'
        } else {
          this.start = true
          this.msgBtn = 'STOP'
          this.colors = 'error'
        }
        this.getOrders();
      } else {
        this.start = false
        this.msgBtn = 'START'
        this.colors = 'success'
      }
    },
    getOrders() {
      UserService.getTradesBot(this.idBot).then(
        response => {
          this.items = response.data;
        },
        error => {
          this.content =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
          if (error.response && error.response.status === 403) {
            EventBus.dispatch("logout");
          }
        }
      );
      // UserService.getOrdersBot(this.idBot).then(
      //   response => {
      //     console.log(response.data)
      //     this.items = response.data;
      //   },
      //   error => {
      //     this.content =
      //       (error.response && error.response.data && error.response.data.message) ||
      //       error.message ||
      //       error.toString();
      //     EventBus.dispatch("logout");
      //   }
      // );
    },
    statusBot() {
      if (this.start === false) {
        this.start = true
        this.msgBtn = 'STOP'
        this.colors = 'error'
        // Iniciar bot
        this.sendStatus(true)
      } else {
        this.start = false
        this.msgBtn = 'START'
        this.colors = 'success'
        // Parar bot
        this.pauseBot(false)
      }
    },
    sendStatus(status) {
      this.message = '';
      this.submitted = true;
      this.bot.started = status
      UserService.postStatusBot(this.bot).then(
        response => {
          this.loadData(response.data)
        },
        error => {
          this.content =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
          this.msgError = error.response
          this.types = 'error'
          this.isShow = true
        }
      );
    },
    pauseBot(status) {
      this.message = '';
      this.submitted = true;
      this.bot.started = status
      UserService.pauseBot(this.bot).then(
        response => {
          this.loadData(response.data)
        },
        error => {
          this.content =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
          this.msgError = error.response
          this.types = 'error'
          this.isShow = true
        }
      );
    },
    getMyClass(item) {
      // console.log(item.type)
      if (item.type === 'BID') {
        // console.log('compraClass')
        return "compraClass"
      } else {
        return "ventaClass"
      }
      // if(item.index === 2  || item.index === 3){
      //   console.log(item)
      //   return "myClass"
      // } else return
    }
  }
}
</script>

<style scoped>
.mytable .v-table tbody tr:not(:last-child) {
  border-bottom: none;
  background-color: blue;
}

.compraClass tbody tr {
  background-color: red !important;
  color: red;
}

.ventaClass {
  background-color: green
}
</style>
